const debug = process.env.NODE_ENV !== 'production';

export default {
  to(refName) {
    if(debug) console.log('scrollMeTo', refName);
    const element = document.getElementById(refName);

    if(!element)
      throw `Unable to find element with id ${refName}, when attempting to scroll to that element`

    element.scrollIntoView();
    focusOnElem(refName);
  },
  setCaret(element, offset) {
    const range = document.createRange();
    const selection = window.getSelection();

    element.focus();
    element.selectionStart = element.selectionEnd = offset;

    range.setStart(element, offset);
    range.setEnd(element, offset);

    selection.removeAllRanges();
    selection.addRange(range);
    scrollTo(range.getBoundingClientRect().top, range.getBoundingClientRect().bottom);
  },
  // copy of to to check other way
  on(refName){
    if(debug) console.log('scrollMeTo', refName);
    const element = document.getElementById(refName);
    if (element){
      const range = document.createRange();
      range.setStart(element, 0);
      range.setEnd(element, 0);
      scrollTo(range.getBoundingClientRect().top, range.getBoundingClientRect().bottom);
    }
  }
};

function focusOnElem(refName){
  // if has a marked input element we focus on it
  var elementInput = document.getElementById(refName + "_in");

  if(elementInput)
    elementInput.focus();

  // if checkbox/select focus on first option for it
  var firstSelectableElem = document.getElementById(refName + "_0");
  if(firstSelectableElem)
    firstSelectableElem.focus();
}

function scrollTo(top, bottom) {
  /** If new cursor position is not visible, scroll to it */
  const { innerHeight } = window;

  if (top < 0) {
    window.scrollBy(0, top);
  }
  if (bottom > innerHeight) {
    window.scrollBy(0, bottom - innerHeight);
  }
}