<template>
  <h3 :id='elementId' style='color:red'>Not found {{type}} Component</h3>
</template>

<script>
export default {
  props: {
    id: Number,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    }
  }
};
</script>

<style scoped>
</style>