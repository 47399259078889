<template>
  <div :id="elementId">
    <label :for="elementId + '_in'" class="question-label">{{ question }}</label>
    <input
      type="number"
      class="form-control"
      :class="validatedClass"
      :id="elementId + '_in'"
      :ref="elementId"
      v-model.lazy="value"
      @focus="commitFocus"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    question: String,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    value: {
      get() {
        return this.$store.getters['fill/answer'](this.id);
      },
      set(value) {
        let payload = { id: this.id, value: value };
        this.$store.dispatch('fill/SAVE_ANSWER', payload);
      }
    },
    validatedClass() {
      return this.$store.getters['fill/validationState'](this.id);
    }
  },
  methods: {
    commitFocus() {
      this.$store.commit('fill/setFocus', this.id);
    }
  }
};
</script>

<style scoped></style>
