<template>
  <button class='edit-button' @click='onClick' v-bind='$attrs'>
    <slot></slot>
  </button>
</template>

<script>
/*
See readme for documentation
*/
export default {
  methods: {
    onClick(event) {
      this.$emit('click', this.network, event);
    }
  }
};
</script>

<style scoped>

.edit-button {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.375rem 1.75rem;
  margin-left: 0.5rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.edit-button:disabled {
  color: #666;
  cursor: not-allowed;
}

.eb-prim {
  background: -moz-linear-gradient(
    top,
    hsl(204, 63%, 62%) 0%,
    hsl(212, 66%, 56%) 100%
  );
  background: -webkit-linear-gradient(
    top,
    hsl(204, 63%, 62%) 0%,
    hsl(212, 66%, 56%) 100%
  );
  background: linear-gradient(
    to bottom,
    hsl(204, 63%, 62%) 0%,
    hsl(212, 66%, 56%) 100%
  );
  color: #fff;
}

.eb-prim:hover:enabled {
  background: -moz-linear-gradient(
    top,
    hsl(204, 63%, 56%) 0%,
    hsl(212, 66%, 50%) 100%
  );
  background: -webkit-linear-gradient(
    top,
    hsl(204, 63%, 56%) 0%,
    hsl(212, 66%, 50%) 100%
  );
  background: linear-gradient(
    to bottom,
    hsl(204, 63%, 56%) 0%,
    hsl(212, 66%, 50%) 100%
  );
}

.eb-sec {
  background: transparent;
  padding: calc(0.375rem - 2px) calc(1.75rem - 2px);
  border: 2px solid #aaa;
  color: #1e7eae;  /* cool-alt-link */
}

.eb-sec:hover:enabled {
  background: #ddd;
  color: #666;
}

.eb-danger {
  background: #fa385f;  /* cool-danger*/
  color: #fff;
}

.eb-danger:hover:enabled {
  background: #d02547; /* cool-danger-alt*/
}

.eb-success {
  background: -moz-linear-gradient(top, #52b86a 0%, #28a745 100%);
  background: -webkit-linear-gradient(top, #52b86a 0%, #28a745 100%);
  background: linear-gradient(to bottom, #52b86a 0%, #28a745 100%);
  color: #fff;
}

.eb-success:hover:enabled {
  background: -moz-linear-gradient(top, #28a745 0%, #1c7430 100%);
  background: -webkit-linear-gradient(top, #28a745 0%, #1c7430 100%);
  background: linear-gradient(to bottom, #28a745 0%, #1c7430 100%);
}

/* Eb fade and eb-subltle are similar */
/* To use against white background. More bold*/
.eb-fade {
  border: 2px solid #efefef;
  padding: calc(0.375rem - 2px) calc(1.75rem - 2px);
  background-color: #efefef;
}

.eb-fade:hover:enabled {
  border: 2px solid #aaa;
  padding: calc(0.375rem - 2px) calc(1.75rem - 2px);
  background-color: #C5C3C6;
  color: #46494C;
}

/* To use against grey background. More sublte */

.eb-subtle {
  background: transparent;
  color: #888;
}

.eb-subtle:hover {
  color: #444;
  background: #dcdcdd;
}

</style>

