export function isStandalone() {
  if (process.env.NODE_ENV !== 'production') console.log('vueIsStandalone');

  let vueIsStandalone = false;
  if (
    process.env.NODE_ENV === 'development' &&
    (window.location.port.startsWith('808')||
    window.location.port.startsWith('807'))
  ) {
    vueIsStandalone = true;
  }
  return vueIsStandalone;
}

export function isUatEnv(){
  return window.location.host.includes('swiftproposals-survey-test.azurewebsites') || window.location.host.includes('swiftproposals3.azurewebsites')
}

const isVueStandalone = isStandalone();
export default function() {
  return isVueStandalone;
}