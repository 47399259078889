<template>
  <div class="page text-center">
    <h3 class="landing-subtitle mb-5">{{loadingText}}</h3>
    <div class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..." style="width: 5rem; height: 5rem;" ></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loadingText: String
  },
};
</script>

<style scoped>
.landing-subtitle {
  margin: 0;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.1;
}
</style>