<template>
  <div :id="elementId">
    <label tabindex="-1" class="question-label col-form-label pt-0 bv-no-focus-ring" style="width:100%"
      >{{ question }}
      <multiselect
        :class="validatedClass"
        v-model="selected"
        :options="options"
        :searchable="false"
        :show-labels="false"
        placeholder="Pick a value"
        @open="commitFocus"
      >
      </multiselect>
    </label>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect
  },
  props: {
    id: Number,
    question: String,
    options: Array,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    selected: {
      get() {
        return this.$store.getters['fill/answer'](this.id);
      },
      set(value) {
        let payload = { id: this.id, value: value };
        this.$store.dispatch('fill/SAVE_ANSWER', payload);
      }
    },
    validatedClass() {
      return this.$store.getters['fill/validationState'](this.id);
    }
  },
  methods: {
    commitFocus() {
      this.$store.commit('fill/setFocus', this.id);
    },
    optionId(idx) {
      return this.elementId + '_' + idx;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.is-invalid .multiselect__tags {
  border-color: #f04124 !important;
}
.is-valid .multiselect__tags {
  border-color: #28a745 !important;
}
</style>
