<template>
  <div :id="elementId">
    <label :for="elementId + '_in'" class="col-form-label question-label">{{ question }}</label>
    <textarea
      v-model.lazy="value"
      class="form-control"
      :class="validatedClass"
      :id="elementId + '_in'"
      @focus="commitFocus"
      v-bind:style="{ height: areaHeight + 'px' }"
      ref="textarea"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    question: String,
    type: String
  },
  data: function() {
    return {
      areaHeight: 108,
      style: null
    };
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    value: {
      get() {
        return this.$store.getters['fill/answer'](this.id);
      },
      set(value) {
        let payload = { id: this.id, value: value };
        this.$store.dispatch('fill/SAVE_ANSWER', payload);
      }
    },
    validatedClass() {
      return this.$store.getters['fill/validationState'](this.id);
    }
  },
  mounted: function() {
    this.setHeight();
  },
  methods: {
    commitFocus() {
      this.$store.commit('fill/setFocus', this.id);
    },
    setHeight: function() {
      let heightOffset = 10;
      this.areaHeight = this.$refs.textarea.scrollHeight + heightOffset;
      if (this.areaHeight < 108) {
        this.areaHeight = 108;
      }
    }
  }
};
</script>

<style scoped></style>
