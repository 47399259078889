const questionTypes = [
  'qshort',
  'qnumber',
  'qyn',
  'qlong',
  'qtable',
  'qoption',
  'qcheck',
  'qselect',
  'qdate',
  'qupload',
  'qsign',
  'qcharityname',
  'qcompanyhousename'
];

const componentsDict = {
  text: 'VText',
  subsection: 'QuestionSubSection',
  subtitle: 'VSubtitle',
  qshort: 'QShort',
  qsign: 'QSign',
  qnumber: 'QNumber',
  qyn: 'QYesNo',
  qlong: 'QLong',
  qtable: 'QTable',
  qoption: 'QOption',
  qcheck: 'QCheck',
  qselect: 'QSelect',
  qdate: 'QDate',
  qupload: 'QUpload',
  qcharityname: 'QCharityName',
  qcompanyhousename: 'QCompanyHouseName'
};

const printComponentsDict = {
  text: 'VText',
  subsection: 'QuestionSubSection',
  subtitle: 'VSubtitle',
  list: 'VList',
  qshort: 'QShortPrint',
  qsign: 'QSignPrint',
  qnumber: 'QShortPrint',
  qyn: 'QYesNoReadonly',
  qlong: 'QLongReadonly',
  qtable: 'QTablePrint',
  qoption: 'QOptionReadonly',
  qcheck: 'QCheckReadonly',
  qselect: 'QOptionReadonly',
  qdate: 'QDate',
  qupload: 'QUpload',
  qcompanyhousename: 'QShortPrint',
  qcharityname:'QShortPrint',
};

function getElem(component) {
  return component.type + component.id;
}

const questionMapper = {
  componentsDict(){
    return componentsDict;
  },
  printComponentsDict(){
    return printComponentsDict;
  },
  isQuestionType(type) {
    return questionTypes.includes(type);
  },
  tableOfContents(form, shouldDisplayFn) {
    // TODO: Title?, sign element, comments
    var result = [];

    for (let index = 0; index < form.sections.length; index++) {
      const element = form.sections[index];
      if (!shouldDisplayFn(element)) continue;

      const bookmark = {
        name: element.text,
        elem: getElem(element),
        childrenWithComments: 0,
        children: [],
        elemId: element.id,
      };
      if (element.sections) {
        element.sections.forEach((question) => {
            if (question.question && shouldDisplayFn(question)) {
            let child = { name: question.question, elem: getElem(question), elemId: question.id };
            bookmark.children.push(child);
          } else if (question.type === 'subsection' && shouldDisplayFn(question)) {
            let child = { name: question.text, elem: getElem(question), elemId: question.id };
            bookmark.children.push(child);
          }
        });
      }

      result.push(bookmark);
    }
    return result;
  },
  extractQuestions(form) {
    return extractQuestionsRecursively(form);
  }
};

function extractQuestionsRecursively(parentElem, prefix){
  let res = [];

  for (let i = 0; i < parentElem.sections.length; i++) {
    const elem = parentElem.sections[i];
    if (elem.question){
      let questionStr= elem.question;
      if (prefix) questionStr = prefix + elem.question;
      res.push({ id: elem.id, question: questionStr})
    } else if (elem.sections){
      let subPrefix = null;
      if(elem.type === 'subsection'){
        let titleTxt = elem.text.length <= 60 ? elem.text : elem.text.substring(0, 57) + "..." 
        subPrefix = titleTxt + ' > ';
      }
      let additionalQs = extractQuestionsRecursively(elem, subPrefix);
      res = [...res, ...additionalQs];
    }
  }
  
  return res;
}

export default questionMapper;
