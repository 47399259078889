const debug = process.env.NODE_ENV !== 'production';

function initialState() {
  return {
    charityNameSearchResults: [],
    companyNameSearchResults: [],
    preFillSuggestions: [],
    proposalIdOrUid: '',
    errorHappened: false,
    noResults: false,
    isSearching: false,
    isLoading: false,
    bankedCharitySearch: '',
    bankedCompanySearch: ''
  };
}
export const getters = {
  preFills: state => questionId => {
    return state.preFillSuggestions.filter(x => x.questionId === questionId);
  }
};

export const mutations = {
  dbPrefill(state, payload) {
    if (debug) console.log('preFill.preFillSuggestions', payload);
    state.preFillSuggestions = payload;
  },
  resetStateWithPayload(state, payload) {
    if (debug) console.log('preFill.resetStateWithPayload ', payload);

    state.errorHappened = false;
    state.noResults = false;
    state.proposalIdOrUid = payload.proposalIdentifier;
    state.charityNameSearchResults = [];
    state.companyNameSearchResults = [];
    state.preFillSuggestions = [];
    state.bankedCharitySearch = '';
    state.bankedCompanySearch = '';
    state.isSearching = false;
    state.isLoading = false;
  },
  saveCharitySearchResults(state, payload) {
    if (this.$debug) console.log('Search Results: ', payload);
    state.charityNameSearchResults = payload;
  },
  resetCharitySearchMeta(state) {
    state.errorHappened = false;
    state.noResults = false;
    state.charityNameSearchResults = [];
  },
  saveCompanySearchResults(state, payload) {
    if (this.$debug) console.log('Search Results: ', payload);
    state.companyNameSearchResults = payload;
  },
  resetCompanySearchMeta(state) {
    state.errorHappened = false;
    state.noResults = false;
    state.companyNameSearchResults = [];
  },
  noSearchResults(state) {
    state.noResults = true;
  },
  searchErrorHappened(state) {
    state.noResults = true;
  },
  saveBankedCharitySearch(state, payload) {
    state.bankedCharitySearch = payload;
  },
  clearBankedCharitySearch(state) {
    state.bankedCharitySearch = '';
  },
  saveBankedCompanySearch(state, payload) {
    state.bankedCompanySearch = payload;
  },
  clearBankedCompanySearch(state) {
    state.bankedCompanySearch = '';
  },
  setIsSearching(state, bool) {
    state.isSearching = bool;
  },
  setIsLoading(state, bool) {
    state.isLoading = bool;
  }
};

const actions = {
  async INIT({ commit, state }, payload) {
    commit('resetStateWithPayload', payload);

    let res = await this._vm.$api.prefill.getPreFill(state.proposalIdOrUid);
    commit('dbPrefill', res.data);
  },

  async SEARCH_CHARITY_NAME({ commit, state, dispatch }, payload) {
    if (state.isSearching === false) {
      commit('resetCharitySearchMeta');
      commit('setIsSearching', true);
      commit('setIsLoading', true);
      try {
        let res = await this._vm.$api.prefill.searchCharities(payload, state.proposalIdOrUid);
        if (res.data.length === 0) {
          commit('noSearchResults');
        } else {
          commit('saveCharitySearchResults', res.data);
        }
      } catch (error) {
        commit('searchErrorHappened');
        if (debug) console.log(error);
      } finally {
        commit('setIsSearching', false);
      }
      dispatch('CHECK_BANKED_CHARITY_SEARCH');
    } else {
      commit('saveBankedCharitySearch', payload);
    }
  },

  async CHECK_BANKED_CHARITY_SEARCH({ commit, state, dispatch }) {
    if (state.bankedCharitySearch !== '') {
      let search = state.bankedCharitySearch;
      commit('clearBankedCharitySearch');
      if (debug) console.log(search);
      await dispatch('SEARCH_CHARITY_NAME', search);
    } else {
      commit('setIsLoading', false);
    }
  },

  async SELECT_CHARITY_RESULT({ commit, state }, payload) {
    if (this.$debug) console.log(payload, state.proposalIdOrUid);
    let res = await this._vm.$api.prefill.getCharity(state.proposalIdOrUid, payload);

    commit('dbPrefill', res.data);
  },

  async SEARCH_COMPANY_NAME({ commit, state, dispatch }, payload) {
    if (state.isSearching === false) {
      commit('resetCompanySearchMeta');
      commit('setIsSearching', true);
      commit('setIsLoading', true);
      try {
        let res = await this._vm.$api.prefill.searchCompaniesHouse(payload, state.proposalIdOrUid);
        if (res.data.length === 0) {
          commit('noSearchResults');
        } else {
          commit('saveCompanySearchResults', res.data);
        }
      } catch (error) {
        commit('searchErrorHappened');
        if (debug) console.log(error);
      } finally {
        commit('setIsSearching', false);
      }
      dispatch('CHECK_BANKED_COMPANY_SEARCH');
    } else {
      commit('saveBankedCompanySearch', payload);
    }
  },

  async CHECK_BANKED_COMPANY_SEARCH({ commit, state, dispatch }) {
    if (state.bankedCompanySearch !== '') {
      let search = state.bankedCompanySearch;
      commit('clearBankedCompanySearch');
      if (debug) console.log(search);
      await dispatch('SEARCH_COMPANY_NAME', search);
    } else {
      commit('setIsLoading', false);
    }
  },

  async SELECT_COMPANY_RESULT({ commit, state }, payload) {
    if (this.$debug) console.log(payload, state.proposalIdOrUid);
    let res = await this._vm.$api.prefill.getCompany(state.proposalIdOrUid, payload);

    commit('dbPrefill', res.data);
  }
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions
};
