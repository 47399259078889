<template>
  <div>
    <a @click="toggleComments" class="float-right attach-shepherd-comment">[{{ this.commentsText }}]</a>
    <div v-if="showComments" class="sadow lay-card mt-2 mb-5 cool-bg-grey1">
      <div class="lay-card-top">
        <Comments
          @comment="onComment"
          @commentToDelete="deleteComment"
          :comments="filteredComments"
          :author="user"
          :isOffline="offline"
        ></Comments>
      </div>
    </div>
  </div>
</template>

<script>
import Comments from '@shared/components/comments/Comments.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Comments
  },
  props: {
    questionId: Number,
  },
  data() {
    return {
      showComments: false
    };
  },
  computed: {
    ...mapState('comments', ['user']),
    ...mapState('fill', ['offline']),
    commentsText() {
      return this.showComments ? 'Hide' : `Comments(${this.filteredComments.length})`;
    },
    filteredComments() {
      return this.$store.getters['comments/comments'](this.questionId);
    }
  },
  methods: {
    toggleComments() {
      this.showComments = !this.showComments;
    },
    onComment(comment) {
      if (!this.offline) {
        var payload = {
          author: this.user,
          message: comment,
          createdAt: new Date(),
          questionId: this.questionId
        };
        this.$store.dispatch('comments/NEW_COMMENT', payload);
      }
    },
    deleteComment(comment) {
      if (!this.offline) {
        this.$store.dispatch('comments/DELETE_COMMENT', comment);
      }
    }
  }
};
</script>

<style></style>
