<template>
  <div class="page text-center">
    <h1 class="landing-title">{{errorCode}}</h1>
    <h3 class="landing-subtitle">{{errorText}}</h3>
    <p class="long-error">{{longErrorMessage}}</p>
  </div>
</template>

<script>
export default {
  props: {
    errorText: {
      type: String,
      default: 'Not Found'
    },
    errorCode: {
      type: String,
      default: '404'
    },
    longErrorMessage: {
      type: String,
      default: ''
    },
  },
};
</script>

<style scoped>
.landing-title {
  margin: 0;
  font-size: 10rem;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.landing-subtitle {
  margin: 0;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.1;
}
.long-error {
  margin: 0;
  font-size: 2rem;
}
</style>