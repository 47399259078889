<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      offlineToastId: null
    };
  },
  computed: {
    ...mapState('fill', ['offline'])
  },
  watch: {
    offline(newOffline) {
      if (this.$debug) console.log('Offline ', newOffline, this.offlineToastId);

      if (newOffline === true) {
        this.offlineToastId = this.$toast.info('We are offline. Unable to Autosave answers', {
          position: 'top-center',
          timeout: false,
          closeOnClick: false,
          closeButton: false
        });
      }

      if (newOffline === false && this.offlineToastId != null) {
        this.$toast.dismiss(this.offlineToastId);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
