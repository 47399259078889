<template>
  <div class='mt-5 mb-5 pg-break-print'>
    <h2 :id='elementId'>{{text}}</h2>
    <div v-for='content in displayComponents' :key='content.id'>
      <ProposalItemHolder :proposalItem="content"></ProposalItemHolder>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "QuestionSection",
  data() {
    return {
      displayComponents: [],
    }
  },
  props: {
    id: Number,
    text: String,
    type: String,
  },
  watch: {
    // We trigger an update when an answer is set as it may make a component visible now
    answers: 'getVisibleComponents'
  },
  created () {
    this.getVisibleComponents();
  },
  computed: {
    ...mapState('fill', ['answers']),
    elementId() {
      return this.type + this.id;
    },
    itemHolderComponent(){
      // we can't import as PrintItemHolder is not avaliable in Broker project
      return this.componentsMode === 'PRINT' ? 'PrintItemHolder': 'ProposalItemHolder';
    }
  },
  methods: {
    getVisibleComponents(){   
      this.displayComponents = this.$store.getters['fill/filteredItems'](this.id);
    },
  },
}
</script>

<style scoped>

</style>
