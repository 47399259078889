<template>
  <ul class="comment-list">
    <VComment
      :key="comment.id"
      @commentToDelete="$emit('commentToDelete', $event)"
      v-for="comment in comments"
      v-bind="comment"
    ></VComment>
  </ul>
</template>

<script>
import VComment from '@shared/components/comments/VComment.vue';

export default {
  name: 'CommentsList',
  components: { VComment },
  props: {
    comments: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped>
.comment-list { 
  padding: 1em 0;
  margin-bottom: 0px;
  list-style-type: none;
}
</style>
