<template>
  <div>
    <p class="text-right pr-3 center-text-trick" style="vertical-align: middle;">
      <span class="pr-2" v-if="multipleAccess" :title="othersAccessTitle">
        {{noAccessingForm}}&nbsp;<i class="fas fa-laptop"></i>
      </span>
      <span  title="This form automatically saves your work" class="attach-shepherd-autosave">
        {{ saveText }} <i class="fas fa-cloud-upload-alt" :class="iconColour"></i>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pause: false
    };
  },
  props: {
    autosaving: Boolean,
    offline: Boolean,
    proposalAccessList: Array
  },
  computed: {
    delayedSaving() {
      return this.autosaving || this.pause;
    },
    saveText() {
      if (this.delayedSaving) return 'Autosaving...';

      return this.offline ? 'Offline' : 'Saved';
    },
    iconColour() {
      return this.offline ? 'cool-danger' : '';
    },
    noAccessingForm(){
      return this.proposalAccessList.length - 1;
    },
    multipleAccess() {
      return this.proposalAccessList.length > 1;
    },
    othersAccessTitle(){
      return `${this.noAccessingForm} others are accessing the form`
    }
  },
  watch: {
    autosaving: function(val) {
      if (val === true) {
        // ensure autosaving is visible for a few seconds to ensure user can see
        this.pause = true;
        setTimeout(() => {
          this.pause = false;
        }, 1000);
      }
    }
  }
};
</script>
<style scoped>
.center-text-trick {
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  margin-bottom: 0;
}
</style>
