<template>
  <h3 class="subtitle-label" :id='elementId'>{{text}}</h3>
</template>

<script>
export default {
  props: {
    id: Number,
    text: String,
    type: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    }
  }
}
</script>

<style scoped>

</style>