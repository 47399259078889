import { render, staticRenderFns } from "./QuestionSection.vue?vue&type=template&id=7a7edca0&scoped=true&"
import script from "./QuestionSection.vue?vue&type=script&lang=js&"
export * from "./QuestionSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a7edca0",
  null
  
)

export default component.exports