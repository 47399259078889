<template>
  <div>
    <div v-for="fill in preFillItems" :key="fill.prefillId">
      <PreFillItem v-bind="fill" @use="onUse(fill.prefillId)"></PreFillItem>
    </div>
  </div>
</template>

<script>
import PreFillItem from '@shared/components/prefill/PreFillItem.vue';  

export default {

  components: { PreFillItem },
  props: {
    questionId: Number
  },
  computed: {
    preFillItems() {
      return this.$store.getters['preFill/preFills'](this.questionId);
    }
  },
  methods: {
    onUse(fillId) {
      let value = this.preFillItems.find(x => x.prefillId === fillId).prefill
      let payload = { id: this.questionId, value: value };
      this.$store.dispatch('fill/SAVE_ANSWER', payload);
    }
  },
};
</script>

<style scoped></style>
