<template>
  <li class="nav-item" :class="isLinkActive ? 'active' : ''">
    <router-link class="nav-link" :to="to">
      <slot></slot>
      <span v-if="isLinkActive" class="sr-only">(current)</span>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    to: String,
    text: String
  },
  computed: {
    isLinkActive() {
      return this.$route.path === this.link;
    }
  }
};
</script>

<style scoped></style>
