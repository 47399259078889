<template>
  <li>
    <a @click='onScrollTo' :class='elementClass' :aria-expanded='collapsed ? "true" : "false"' :aria-controls='toggleId'>{{displayName}}</a>
    <b-collapse :id='toggleId' v-model='collapsed'>
      <ul class='list-unstyled show'>
        <StepperItem v-for='(bookmark, bIndex) in children' :key='bIndex' v-bind='bookmark' :focusedIds='focusedIds'></StepperItem>
      </ul>
    </b-collapse>
  </li>
</template>

<script>
import scrollMeTo from '@shared/components/stepper/utils/scrollMeTo.js';
import StepperItem from '@shared/components/stepper/ui/StepperItem.vue';

export default {
  components: {
    StepperItem
  },
  props: {
    name: String,
    elem: String,
    elemId: Number,
    childrenWithComments: {
      type: Number,
      default: () => 0
    },
    children: Array,
    focusedIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      collapsed: false,
      isFocused: false
    };
  },
  watch: {
    // We have to do this using watch as array (its reactivity is been cached)
    focusedIds: 'updateIsSelected'
  },
  computed: {
    toggleId() {
      return `${this.elem}-toggle`;
    },
    elementClass() {
      if (this.isFocused) {
        return 'focused';
      }

      if (!this.collapsed) {
        return 'collapsed';
      }

      return null;
    },
    displayName(){
      if(this.name && this.name.length > 0)
        return this.name;

      return 'Section'
    }
  },
  methods: {
    onScrollTo() {
      this.collapsed = !this.collapsed;
      scrollMeTo.to(this.elem);
    },
    updateIsSelected() {
      if(this.$debug) console.log('updateIscollapsed');
      this.isFocused = this.focusedIds.includes(this.elemId);
      this.collapsed = this.focusedIds.includes(this.elemId);
    }
  }
};
</script>

<style scoped>
a[data-toggle='collapse'] {
  position: relative;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
li a:hover {
  color: #214245;
  background: #aed1e6;
}

.focused {
  color: #214245;
  background: #aed1e6;
}
</style>
