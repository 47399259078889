<template>
  <div class="autofill-block alert alert-info" role="alert">
      <div class="autofill-l"><b>{{sourceComputed}}</b>: {{prefill}}</div>
      <div class="autofill-r"><a class="alert-link" @click="$emit('use')">Use</a></div>
  </div>
</template>

<script>
  export default {
    props: {
      source: String,
      prefill: String,
    },
    computed: {
      sourceComputed() {
        if(this.source === 'Renewal'){
          return 'Last Year'
        }

        return this.source 
      }
    },
  }
</script>

<style scoped>
.autofill-block{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.autofill-r{
  flex-basis: 30px;
}
</style>