<!-- Styling from: https://codepen.io/cesar_mejiag/pen/wKMBpP -->
<template>
  <div class="comments-app">
    <CommentsList v-if="comments.length > 0" @commentToDelete="$emit('commentToDelete', $event)" :comments="comments"></CommentsList>
    <hr v-if="comments.length > 0" />
    <transition
      appear
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__slideInLeft"
      leave-active-class="animated bounceOutRight"
    >
      <NewComment @comment="$emit('comment', $event)" :author="author" :isOffline="isOffline"></NewComment>
    </transition>
  </div>
</template>

<script>
import CommentsList from '@shared/components/comments/CommentsList.vue';
import NewComment from '@shared/components/comments/NewComment.vue';

export default {
  components: { NewComment, CommentsList },
  props: {
    comments: {
      type: Array,
      default: () => [],
    },
    author: {
      type: String,
      default: 'User',
    },
    isOffline:  {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onComment(comment) {
      this.$emit('comment', comment);
    },
    deleteComment(comment) {
      this.$emit('commentToDelete', comment);
    },
  },
};
</script>
<style>
.comments-app {
  margin: 0px auto;
  max-width: 680px;
  padding: 0 50px;
  width: 100%;
  font-family: 'Roboto', 'Tahoma', 'Arial', sans-serif;
}

.comment-avatar {
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  height: 80px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 80px;
}
</style>
