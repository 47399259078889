<!-- file to be changed -->
<template>
  <nav class="sidebar sidebar-sticky" :class="sidebarActiveClass" :style="{background: sidebarColour}">
    <div class="sidebar-main">
      <VAutosaving 
        :autosaving="autosaving" 
        :offline="offline" 
        :proposalAccessList="proposalAccessList">
      </VAutosaving>
      <div style="height:calc(100vh - 40px)" class="attach-shepherd-sidebar">
        <Vuescroll>
          <div class="sidebar-header">
            <h3>Table Of Contents</h3>
          </div>
          <ul class="list-unstyled components">
            <StepperSection
              v-for="(bookmarkSection, index) in bookmarks"
              :key="index"
              v-bind="bookmarkSection"
              :focusedIds="focusedIds"
            >
            </StepperSection>
          </ul>
        </Vuescroll>
      </div>
    </div>
    <button class="btn btn-light sidebar-handle attach-shepherd-hide" @click="toggleSidebar">
      <i class="fas" :class="sidebarHandleIconClass"></i>
    </button>
    <v-dialog />
  </nav>
</template>

<script>
/**
 * This element's parent div must have .lay-nav-wrapper class
 *
 */

import Vuescroll from 'vuescroll';
import StepperSection from '@shared/components/stepper/ui/StepperSection.vue';
import VAutosaving from '@shared/components/stepper/ui/VAutosaving.vue';

export default {
  components: {
    Vuescroll,
    StepperSection,
    VAutosaving
  },
  props: {
    bookmarks: {
      type: Array,
      default: () => []
    },
    focusedIds: {
      type: Array,
      default: () => []
    },
    active: {
      type: Boolean,
      default: () => false
    },
    autosaving: {
      type: Boolean,
      default: () => false
    },
    offline: {
      type: Boolean,
      default: () => false
    },
    proposalUniqueLink: {
      type: String,
      default: () => null
    },
    proposalAccessList: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      internalActive: false
    };
  },
  computed: {
    sidebarActiveClass() {
      return this.internalActive ? 'active' : '';
    },
    bookmarksEnriched() {
      return this.bookmarks.map(x => Object.assign({ visible: false, focusedIds: this.focusedIds }, x));
    },
    sidebarHandleIconClass() {
      return this.internalActive ? 'fa-angle-double-right' : 'fa-angle-double-left';
    },
    sidebarColour() {
      let hexCode = this.$store.state.fill.meta.sidebarColour;
      return hexCode ? hexCode : '#214245';
    }
  },
  methods: {
    init(value) {
      this.internalActive = value;
    },
    getToggleIdQuotes(str) {
      return `'${this.getToggleId(str)}'`;
    },
    toggleSidebar() {
      this.internalActive = !this.internalActive;
    },
    onOfflineFill() {
      // This code should be temporary
      let self = this;
      this.$api.demo.postGetOfflineFormDoc(this.proposalUniqueLink);
      
      this.$modal.show('dialog', {
        title: 'Unavaliable',
        text: '<p>Sorry this is not available right now.<br/> Please email your broker for a copy you can fill offline.</p>',
        buttons: [
          {
            title: 'Ok',
            handler: () => {
              self.$modal.hide('dialog');
            }
          }
        ]
      });
    }
  },
  mounted() {
    this.init(this.active);
  },
  watch: {
    active: function(value) {
      this.init(value);
    }
  }
};
</script>

<style scoped>
.sidebar-sticky {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  min-width: 328px;
  max-width: 328px;
}

.sidebar {
  color: #fff;
  transition: all 0.3s;
}

.sidebar.active {
  margin-left: -300px;
  min-width: 326px;
  max-width: 326px;
}

@media (max-width: 768px) {
  .sidebar {
    margin-left: -300px;
  }
  .sidebar.active {
    margin-left: 0;
  }
}

.sidebar-header {
  padding: 0.5rem;
}

.sidebar-main {
  width: 300px;
  height: 100vh;
  float: left;
}
.sidebar-handle {
  width: 20px;
  height: 100vh;
}

.sidebar.active .sidebar-handle {
  border-radius: 0px;
}

.fas {
  margin-left: -7px;
  margin-right: -0px;
}

.button-container
{
  display: flex;
  justify-content: center;
  margin-right: 10px;
}
</style>
