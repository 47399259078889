<template>
  <div class="v-header-edit">
    <h3 v-if="!edit" @click="onLabelClick" class="v-header-edit-label">
      {{ internalText }}<span class="v-header-edit-badge"><i class="fas fa-pencil-alt"></i></span>
    </h3>
    <input
      type="text"
      v-if="edit"
      v-model="internalText"
      v-on:blur="updateText('blur')"
      :placeholder="vplaceholder"
      class="v-header-edit-input form-control"
      @keyup.enter="updateText('enter')"
      ref="headeredit"
    />
  </div>
</template>

<script>
// Credit: https://github.com/myokyawhtun/label-edit/blob/master/src/LabelEdit.vue

export default {
  name: 'VHeaderEdit',
  props: {
    // parent should provide :text or :placeholder
    text: String,
    placeholder: {
      type: String,
      default: 'Enter some text'
    },
    allowEmpty: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      edit: false, // define whether it is in edit mode or not
      internalText: '', // v-bind data model for input text
      allowEmit: true
    };
  },
  computed: {
    vplaceholder: function() {
      return this.placeholder;
    }
  },
  methods: {
    initText(value) {
      if (this.allowEmpty === false && (value == undefined || isEmpty(value))) {
        this.internalText = this.vplaceholder;
      } else {
        this.internalText = value;
      }
    },
    onLabelClick: function() {
      this.edit = true;
    },
    updateText: function(evType) {
      this.edit = false;
      if (this.allowEmpty === false && isEmpty(this.internalText)) {
        this.internalText = this.text;
      }

      if (this.allowEmit === true) {
        this.allowEmit = false;
        this.$emit(`text-updated-${evType}`, this.internalText); // emit specific event
        this.$emit(`text-updated`, this.internalText); // emit generic event
        setTimeout(() => { this.allowEmit = true; }, 1000); // Don't allow to emit for a sec to prevent duplicate emit
      }

    }
  },
  // Whenever updated lifecyle force it to focus on the element. This is important to make sure the blur event works as expected
  updated() {
    var el = this.$refs.headeredit;
    if (el != null) {
      el.focus();
    }
  },
  mounted() {
    this.initText(this.text);
  },
  watch: {
    text: function(value) {
      this.initText(value);
    }
  }
};


function isEmpty(str){
  return (str.length === 0 || !str.trim());
}
</script>

<style scoped>
.v-header-edit {
  width: 100%;
}

.v-header-edit-input {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.2;
}

.v-header-edit-badge {
  font-size: 0.5em !important;
  margin-left: 0.5em;
  position: relative;
  top: -15px;
  display: none;
}

.v-header-edit-label:hover > .v-header-edit-badge {
  display: inline;
}
</style>
