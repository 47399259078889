<template>
  <div :id="elementId">
    <label class="question-label">{{ question }}</label>
    <vue-dropzone
      ref="questionDropzone"
      :id="elementId + '_drop'"
      :class="validatedClass"
      :options="dropzoneOptions"
      @vdropzone-removed-file="onRemovedFile"
      @vdropzone-success-multiple="onUploadComplete"
      @vdropzone-success="onUploadComplete"
      @vdropzone-error-multiple="onUploadFailed"
      @vdropzone-error="onUploadFailed"
    ></vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { mapState } from 'vuex';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    id: Number,
    question: String,
    filename: String,
    type: String
  },
  // Removed files is triggered on destroying. This handles that issue: https://gitlab.com/meno/dropzone/-/issues/171
  data() {
    return {
      is_destroying: false,
      headers: {
          'Access-Control-Allow-Origin': 'Access-Control-Allow-Methods: GET, POST'
        },
    };
  },
  beforeDestroy() {
    this.is_destroying = true;
  },
  destroyed() {
    this.is_destroying = false;
  },
  computed: {
    ...mapState('fill', ['proposalIdOrUid']),
    uploadUrl() {
      let fullPath = this.$api.proposal.urlSupportingDocUpload(this.proposalIdOrUid, this.id);
      return fullPath;
    },
    dropzoneOptions() {
      return {
        url: this.uploadUrl,
        thumbnailWidth: 150,
        maxFilesize: 10,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-upload'></i> Drop your files here or click to upload a file",
        headers: this.headers
      };
    },
    uploadedFiles() {
      var files = this.$store.getters['fill/uploadedFiles'](this.id);
      return files;
    },
    elementId() {
      return this.type + this.id;
    },
    validatedClass() {
      return this.$store.getters['fill/validationState'](this.id);
    }
  },
  async created(){
    if(process.env.VUE_APP_TYPE === 'broker-app'){
      const headers = await getAuthHeaders();
      this.headers.Authorization = headers.headers.Authorization;
    }
  },
  mounted() {
    this.$nextTick(function() {
      // sometimes got an error could not find dropzone ref so added this so
      // code that will run only after the entire view has been rendered
      this.addFilesFromServer();
    });
  },
  methods: {
    addFilesFromServer() {
      if (this.$debug) console.log('adding server files');

      let dzRef = this.$refs.questionDropzone;
      this.uploadedFiles.forEach(uploaded => {
        let url = this.$api.proposal.urlSupportingDocDownload(this.proposalIdOrUid, uploaded.id);
        let file = { name: uploaded.originalFileName, size: uploaded.fileSize };
        dzRef.manuallyAddFile(file, url);
      });
    },
    onRemovedFile(file) {
      if (this.is_destroying) {
        if (this.$debug) console.log('Skip remove file: in destroying mode');
        return;
      }

      if (this.$debug) console.log('removing file, ', file);

      this.uploadedFiles.forEach(uploaded => {
        if (uploaded.originalFileName === file.name) {
          this.$store.dispatch('fill/REMOVE_SUPPORT_DOC', { supportingDocId: uploaded.id });
        }
      });
    },
    onUploadComplete() {
      if (this.$debug) console.log('Uploading Files complete ');

      this.$store.dispatch('fill/FETCH_SUPPORT_DOCS');
    },
    onUploadFailed() {
      if (this.$debug) console.log('Uploading Files failed ');

      this.$toast.error("We were unable to upload the file. Please retry later.");
    }
  }
};
</script>
<style scoped>
ul {
  list-style-type: none;
}
.vue-dropzone.is-invalid{
  border-color: #f04124 !important;
}
.vue-dropzone.is-valid{
  border-color: #28a745 !important;
}
</style>
