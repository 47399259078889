import { render, staticRenderFns } from "./VText.vue?vue&type=template&id=328ef089&scoped=true&"
import script from "./VText.vue?vue&type=script&lang=js&"
export * from "./VText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "328ef089",
  null
  
)

export default component.exports